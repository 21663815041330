import React from 'react';
import axios from 'axios';

const PwnedForm = ({ email, setEmail, breaches, setBreaches }) => {

  const getBreaches = async (event) => {
    event.preventDefault();
    const request = axios.get(`/breaches/${email}`);
    const data = await request.then(response => {
      setBreaches(response.data);
      return response.data;
    }).catch((error) => {
      console.log(error);
      return 0;
    });

    console.log("data", data);
  }

  const handleInputChange = (event) => {
    console.log(event.target.value);
    setEmail(event.target.value);
  }

  return (
    <form onSubmit={getBreaches}>
      <input type="email" value={email} placeholder="email" onChange={handleInputChange}/>
      <input type="submit" value="pwned?"/>
    </form>
  );
}

export default PwnedForm;