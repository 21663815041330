import React, { useState, useMemo } from 'react';

const BreachesTable = (breaches) => {
  const [sortConfig, setSortConfig] = useState(null);
  const [search, setSearch] = useState('');

  const setSortBy = (field) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.field === field && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({field, direction});
  }

  const sortableBreaches = useMemo(() => {
    let breachList = [...breaches.breaches].filter((breach) => {
      return breach.Name.includes(search) || breach.BreachDate.includes(search) || breach.Description.includes(search)
    });
    if (sortConfig !== null) {
      breachList.sort((a, b) => {
        if (a[sortConfig.field] < b[sortConfig.field]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.field] > b[sortConfig.field]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    console.log(breachList);
    return breachList;
  }, [breaches.breaches, sortConfig, search]);

  const createMarkup = (description) => {
    return {__html: description};
  }

  const handleSearch = (event) => {
    setSearch(event.target.value);
  }

  return (
    <div>
      {sortableBreaches.length > 0 ?
      <h3>Your account's been breached</h3> :
      <h3>No breaches found!</h3>
      }
      <label htmlFor="search">
        Search for Breach:
        <input id="search" type="text" onChange={handleSearch} />
      </label>
      <table>
        <thead>
          <tr>
            <th>
              <button type="button" onClick={() => setSortBy('Name')}>
              Name
              </button>
            </th>
            <th>
              <button type="button" onClick={() => setSortBy('BreachDate')}>
              Breach Date
              </button>  
            </th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {sortableBreaches.map(breach => (
            <tr key={breach.Description}>
              <td>{breach.Name}</td>
              <td>{breach.BreachDate}</td>
              <td dangerouslySetInnerHTML={createMarkup(breach.Description)}></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default BreachesTable;