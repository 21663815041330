import React, { useState } from 'react';
import PwnedForm from './components/PwnedForm';
import BreachesTable from './components/BreachesTable';

const App = () => {
  const [ email, setEmail ] = useState('');
  const [ breaches, setBreaches ] = useState(null);

  return (
    <div className="App">
        <h1>';--have i been pwned?</h1>
        <p>
          Check if your email address is in a data breach
        </p>
        {breaches === null ? 
        <PwnedForm email={email} setEmail={setEmail} breaches={breaches} setBreaches={setBreaches}/> : 
        <BreachesTable breaches={breaches}/>
        }
    </div>
  );
}

export default App;
